<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("educationPlan.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button v-b-modal.modal-1>{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th class="text-left">
                  {{ $t("educationPlan.name") }}
                </th>
                <th class="text-left">
                  {{ $t("educationPlan.subjectName") }}
                </th>      
                <th class="text-left">
                  {{ $t("educationPlan.level") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.subjectName }}</td>
                <td>{{ item.level }}</td>
                <td>
                  <b-button
                      type="submit"
                      @click="editPlan(item)"
                      variant="primary"
                  >
                    {{ $t("common.edit") }}
                  </b-button>
                  <router-link :to="{ name: 'teacherEduPlanDetail', params: { id: item.id }}" class="btn btn-icon btn-success">
                    <i class="flaticon2-arrow"></i>
                  </router-link>
                  <button class="btn btn btn-icon btn-success btn-danger" @click="deleteItem(item)" style="margin-left: 10px">
                    <i class="flaticon2-trash"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>

            <div>
              <b-modal id="modal-1" ref="eduPlan-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("educationPlan.name") }}
                    </template>
                    <b-form-input
                        v-model="form.name"
                        :state="eduPlanModelState.name"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ eduPlanModelSaveError.name }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("educationPlan.level") }}
                    </template>
                    <b-form-select
                        v-model="form.level"
                        :options="levels"
                        :state="eduPlanModelState.level"
                    ></b-form-select>
                    <div class="invalid-feedback">{{ eduPlanModelSaveError.level }}</div>
                  </b-form-group>
                  
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("educationPlan.eduHours") }}
                    </template>
                    <b-form-input
                        v-model="form.eduHours"
                        :state="eduPlanModelState.eduHours"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ eduPlanModelSaveError.eduHours }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("educationPlan.subject") }}
                    </template>
                    <b-form-select
                        v-model="form.subjectId"
                        :options="subjects"
                        :state="eduPlanModelState.subjectId"
                    ></b-form-select>
                    <div class="invalid-feedback">{{ eduPlanModelSaveError.subjectId }}</div>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "teacherEduPlan",
  components: {
    KTPortlet
  },
  data() {
    return {
      list: null,
      dialog: true,
      form: {
        name: "",
        level: "",
        eduHours: "",
        subjectId: ""
      },
      show: true,
      subjects: [],
      levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      eduPlanModelState:{},
      eduPlanModelSaveError:{}
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;

      this.eduPlanModelState = {};
      this.eduPlanModelSaveError = {};
      ApiService.postSecured("eduPlans/save", this.form)
          .then(function () {
            $this.$refs["eduPlan-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.eduPlanModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.eduPlanModelState[i] = false;
              }
            }
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["eduPlan-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("eduPlans/list", {
        params: {groupId: this.$route.params.id}
      }).then(({data}) => {
        this.list = data.list;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/subjects")
          .then(({data}) => {
            this.subjects = data.dictionary;
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    cleanModel() {
      this.form = {};
    },
    editPlan(plan) {
      this.form = plan;
      this.$refs["eduPlan-modal"].show();
    },
    deleteItem(plan) {
      if(!confirm("Удалить?")){
        return;
      }
      
      let $this = this;
      ApiService.postSecured("eduPlans/delete", {id:plan.id})
          .then(function () {
            $this.reloadData();
          })
          .catch(() => {
            $this.reloadData();
          });
    }
  }
};
</script>
